import { render, staticRenderFns } from "./ServiceDetail.vue?vue&type=template&id=8f8c2512&scoped=true&"
import script from "./ServiceDetail.vue?vue&type=script&lang=js&"
export * from "./ServiceDetail.vue?vue&type=script&lang=js&"
import style0 from "./ServiceDetail.vue?vue&type=style&index=0&id=8f8c2512&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f8c2512",
  null
  
)

export default component.exports