<template>
  <div id="product-order" :class="`page ${$route.meta.headerType}`">
    <div class="container">
      <div class="page-header">
        <h1 class="title">{{ $__t('예약이 완료되었어요') }}</h1>
        <div class="reserve-info">
          <div class="title">
            {{ order?.service.product_name }}
          </div>
          <ul>
            <li>
              <div class="key">이용 예정일</div>
              <div class="value">
                {{ useDate }}
              </div>
            </li>
            <li>
              <div class="key">이용 예정 시간</div>
              <div class="value">
                {{ `${$moment(order?.reserve_date_start).format('HH:mm')} - ${$moment(order?.reserve_date_end).format('HH:mm')}` }}
                ({{
                  $__t('{hour}시간', {
                    hour: $moment(order?.reserve_date_end).diff(order?.reserve_date_start, 'hour')
                  })
                }})
              </div>
            </li>
            <li>
              <div class="key">수량</div>
              <div class="value">{{ order?.reserve_quantity }}개</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="page-body">
        <template>
          <!--결제금액-->
          <div class="section price">
            <div class="section-header">
              <h2>
                {{ $__t('{check-out-info}.payment_amount') | capitalizeFirstLetter }}
              </h2>
            </div>
            <div class="section-body">
              <div class="price-section">
                <dl>
                  <dt class="title"></dt>
                </dl>
                <dl class="secondary">
                  <dt class="title">
                    {{ $__t('상품금액') | capitalizeFirstLetter }}
                  </dt>
                  <dd class="description">{{ predictedAmount.toLocaleString() }} {{ $__t('won') }}</dd>
                </dl>
                <dl class="secondary">
                  <dt class="title">{{ $__t('쿠폰') | capitalizeFirstLetter }}·{{ $__t('코드') }}할인</dt>
                  <dd class="description">
                    {{ discountAmount > 0 ? '-' + discountAmount.toLocaleString() : 0 }}
                    {{ $__t('won') }}
                  </dd>
                </dl>
              </div>
              <div class="price-section">
                <dl class="main-item">
                  <dt class="title">총 결제금액</dt>
                  <dd class="description">
                    <span
                      ><strong>{{ predictedAmount }}</strong> {{ $__t('won') }}</span
                    >
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceDetail',

  props: ['order_id', 'ticket'],

  data() {
    return {};
  },

  computed: {
    order() {
      return this.$store.getters['orders/GET_ITEM']({
        key: 'order_id',
        value: this.order_id
      });
    },
    useDate() {
      let result = '';
      result = this.$moment(this.order?.reserve_date_start).format('YYYY.MM.DD');
      let day = this.$moment(this.order?.reserve_date_start).day();
      switch (day) {
        case 1:
          result = result + ' (월)';
          break;

        case 2:
          result = result + ' (화)';
          break;

        case 3:
          result = result + ' (수)';
          break;

        case 4:
          result = result + ' (목)';
          break;
        case 5:
          result = result + ' (금)';
          break;
        case 6:
          result = result + ' (토)';
          break;
        case 7:
          result = result + ' (일)';
          break;
      }

      return result;
    },
    predictedAmount() {
      return (this.order?.predicted_amount || 0).toLocaleString();
    },
    discountAmount() {
      return (this.order?.payment?.discount || 0).toLocaleString();
    },
    originAmount() {
      return (this.order?.predicted_amount || 0).toLocaleString();
    }
  },

  async created() {
    try {
      //   await this.fetchOrder();
    } catch (e) {
      console.error(e);
    }
  },

  async mounted() {
    // console.log('order', this.order);
  },

  beforeMount() {},
  beforeDestroy() {},

  methods: {},

  components: {}
};
</script>
<style scoped lang="scss">
#product-order {
  @include container('& > .container', $max-content-width);

  & > .container {
    height: 100%;
    background-color: $color-white;

    .page-header {
      padding: unit(14) unit(16);
      flex-direction: column;
      // background-color: #f1f5f5;

      > .title {
        font-weight: 500;
        font-size: unit(14);
        line-height: unit(32);
        color: #4d4dff;
      }

      .secondary {
        margin-top: unit(6);
        font-size: unit(14);
        line-height: unit(20);
        color: #878d91;
      }

      .reserve-info {
        .title {
          margin-top: unit(10);
          margin-bottom: unit(19);
          font-size: unit(16);
          font-weight: 600;
          line-height: unit(24);
        }

        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .key {
            font-weight: 400;
            font-size: unit(14);
            line-height: unit(20);
          }
          .value {
            font-weight: 600;
            font-size: unit(16);
            line-height: unit(24);
          }
        }

        &__content {
          padding: unit(20) unit(16);

          .content-header {
            font-weight: 500;
            font-size: unit(14);
            line-height: 1.57;
          }

          .content-body {
            margin-top: unit(6);
            font-size: unit(14);
            line-height: 1.57;
            letter-spacing: -0.04em;
            color: #878d91;
          }
        }

        &__button {
          height: unit(50);
          border-top: solid 1px #e1e4e6;
          color: #878d91;
        }
      }
    }

    .page-body {
      margin-top: unit(20);
      padding: 0;
      margin-bottom: unit(20);
    }

    .section {
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: unit(10);
        background: #f1f5f5;
      }

      .section-header {
        display: flex;
        align-items: flex-start;
        min-height: unit(50);
        padding: unit(13) unit(16);
        border-bottom: solid 1px #eaeeef;

        h2 {
          flex: 1;
          font-weight: 500;
          font-size: unit(16);
          line-height: unit(23);
          color: $color-gray-9;
        }

        .arrow {
          flex: 0 0 2 * $unit;
          width: 2 * $unit;
          height: 2 * $unit;
        }
      }

      .section-body {
        padding: unit(20) unit(16);
        .lug-phone {
          .body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: unit(8);

            .lug-select {
            }

            .lug-textinput {
              width: 0;
            }

            .btn-certify {
              background-color: #48d9eb;
              border-radius: 16px;
              color: white;
              min-width: unit(80);

              &.disabled {
                pointer-events: none;
                opacity: 0.5;
              }
            }
          }
        }
        .lug-phone-certify {
          margin-top: unit(8);

          .body {
            .certNum {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              gap: unit(8);

              width: 100%;
              height: unit(34);

              input {
                border-bottom: solid 1px #eaeeef;
              }

              .btn-verify {
                background-color: #48d9eb;
                border-radius: 16px;
                color: white;
                max-width: unit(80);
              }
            }
          }
        }
      }

      &.reserve {
        padding: 0;

        .section-header {
          border-bottom: solid 1px #eaeeef;
        }

        .section-body {
          padding-bottom: unit(30);
          display: flex;
          gap: unit(40);

          .content {
            flex: 1;
            min-width: 0;

            .primary {
              font-size: unit(14);
              line-height: unit(20);
              @include ellipsis(1);
            }

            .secondary {
              padding-top: unit(8);
              font-size: unit(14);
              line-height: unit(20);
              color: #878d91;
              @include ellipsis(2);
            }
          }

          .button {
            align-self: flex-end;
            flex: 0 0 auto;

            button {
              border: 1px solid #008fff;
              border-radius: unit(30);
              padding: unit(3) unit(9);
              color: #008fff;
              line-height: unit(17);
              text-transform: uppercase;
              font-weight: 500;
            }
          }
        }
      }

      &.booker {
        border: 0;

        .ui-form-list-item {
          & + .ui-form-list-item {
            margin-top: unit(28);
          }
        }
      }

      &.pay-method {
        .section-body {
          padding-bottom: unit(30);
          min-height: unit(62);
          .method-area {
            display: flex;
          }
        }

        dl {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          line-height: normal;
          gap: unit(20);

          & + dl {
            margin-top: unit(12);
          }

          dt {
            font-size: unit(14);
            line-height: unit(24);
            flex-shrink: 0;
            min-width: unit(100);

            &.required {
              color: #ff5d5d;
            }
          }

          dd {
            display: flex;
            justify-content: flex-end;
            gap: unit(2);
            min-width: unit(100);

            .icon {
              display: inline-block;
              width: unit(24);
              height: unit(24);
              vertical-align: middle;
              bottom: unit(1);
              min-width: 0;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .text {
              min-width: 0;
              font-size: unit(14);
              line-height: unit(24);
              color: #878d91;
            }
          }
        }
      }

      &.price {
        .section-header {
          display: block;
        }

        dl {
          display: flex;
          align-items: flex-start;
          line-height: normal;

          & + dl {
            margin-top: unit(12);
          }

          dt {
            flex: 1;
            font-weight: 500;
            font-size: unit(14);
            line-height: 1.57;
            letter-spacing: -0.04em;
            color: #61676c;

            .title__button {
              margin: 0 unit(2);
              top: unit(4);
              width: unit(18);
              height: unit(18);
              object-fit: contain;
              display: inline-block;

              & > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          dd {
            flex: 0 0 auto;
            font-size: unit(14);
            line-height: 1.57;
            letter-spacing: -0.04em;
          }

          &.secondary {
            padding-left: unit(14);

            dt {
              color: #878d91;
              font-weight: normal;
            }
          }

          strong {
            font-weight: bold;
            font-size: unit(16);
            letter-spacing: -0.04em;
          }
        }

        .price-section {
          padding: unit(20) 0;

          &:first-of-type {
            padding-top: 0;
          }

          & + .price-section {
            border-top: solid 1px #e1e4e6;
          }
        }

        .main-item {
          display: flex;
          align-items: center;

          .title {
            font-size: unit(14);
            font-weight: 500;
            color: $color-black;
          }

          .secondary {
            font-size: unit(12);
            line-height: unit(26);
            text-align: right;
            color: #008fff;

            strong {
              font-weight: bold;
              font-size: unit(18);
              line-height: unit(26);
              color: #008fff;
            }
          }
        }
        .section-header {
          .primary {
            color: $color-gray-9;
          }

          .secondary {
            color: #008fff;
            font-size: unit(12);

            strong {
              font-weight: bold;
              font-size: unit(16);
            }
          }
        }
      }

      &.condition {
        &::after {
          display: none;
        }

        .section-header {
          display: flex;
          align-items: center;

          h3 {
            flex: 1;
            font-weight: 500;
            font-size: unit(12);
          }

          button {
            flex: 0 0 auto;
            width: auto;
            display: inline-block;
            padding: unit(3) unit(9);

            & > * {
              font-size: unit(12);
              color: #a9afb3;
              text-decoration-line: underline;
            }
          }
        }

        .section-body {
          h4 {
            font-weight: 500;
            font-size: unit(12);
            line-height: unit(17);
            color: #878d91;
            margin-bottom: unit(12);
          }

          p {
            line-height: unit(17);
            color: #878d91;

            & + p {
              margin-top: unit(16);
            }
          }
        }
      }

      &.footer {
        background-color: #f1f5f5;
        padding: unit(30) unit(16);

        &::after {
          // height: unit(70);
        }

        .footer-item {
          padding: unit(16);
          background-color: $color-white;

          &--transparent {
            background-color: transparent;
            padding-bottom: 0;
            line-height: 1.5;
            letter-spacing: -0.02em;
            color: #878d91;
          }

          & + .footer-item {
            margin-top: unit(20);
          }

          .title {
            font-weight: 500;
            font-size: unit(14);
            letter-spacing: -0.02em;
            display: flex;
            flex-direction: column;

            img {
              width: unit(24);
              height: unit(24);
              object-fit: contain;
              bottom: unit(1);
            }

            &__text {
              margin-top: unit(3);
            }
          }

          .description {
            margin-top: unit(6);
            font-weight: normal;
            letter-spacing: -0.02em;
            line-height: unit(17);

            strong {
              font-weight: 700;
            }
          }

          & + .footer-item {
            border-top: solid 1px #eaeeef;
          }

          &:last-child {
            color: #878d91;
          }
        }
      }
    }

    .button-area {
      padding: unit(14) unit(16) unit(14) unit(16);
      background-color: $color-white;

      button {
        background: #a9afb3;
        border-radius: unit(5);
        color: $color-white;
        text-transform: uppercase;
        font-weight: 500;
        font-size: unit(15);
        padding: unit(10) unit(20);

        &.activated {
          background-color: #0099ff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
